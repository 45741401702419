import { Component, OnInit,Output, EventEmitter } from '@angular/core';
import * as $ from "jquery";
import {TranslateService} from '@ngx-translate/core';
import { Router } from "@angular/router"
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {BannerDisplayComponent} from '../banner-display/banner-display.component';
import { LoginService } from './../Service/login.service';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})

export class IndexComponent implements OnInit {
  @Output() langEvent = new EventEmitter<string>();
  set_lang:any='';
  panelOpenState:any;
  constructor(
    private translate: TranslateService,
    private router: Router,
    private matDialog: MatDialog,
    private LoginServ : LoginService 
  ) {
    translate.addLangs(['en', 'ch']);
    translate.setDefaultLang('en');
    this.set_lang=sessionStorage.getItem('set_lang');
    if(this.set_lang == '' || this.set_lang == undefined || this.set_lang == null)
    {
      translate.setDefaultLang('en');
      this.set_lang = 'en';
    }else{
      translate.setDefaultLang(this.set_lang);
    }
   }
   matDialogRefDteails: MatDialogRef<BannerDisplayComponent>;
  ngOnInit() {
     const body = document.getElementsByTagName('body')[0];
    body.classList.add('indexContainer');
   
    $('.scroll-link').on('click', function(event){
      event.preventDefault();
      var sectionID = $(this).attr("data-id");
      this.scrollToID('#' + sectionID, 750);
  });
  // scroll to top action
  $('.scroll-top').on('click', function(event) {
      event.preventDefault();
      $('html, body').animate({scrollTop:0}, 'slow');         
  });
  // mobile nav toggle
  $('#nav-toggle').on('click', function (event) {
      event.preventDefault();
      $('#main-nav').toggleClass("open");
  });
  $(".seq-preloader").fadeOut(); // will first fade out the loading animation
  $(".sequence").delay(500).fadeOut("slow"); // will fade out the white DIV that covers the website.
  
  this.LoginServ.GetBannerDetail().subscribe((CRes)=>{
    try{
      if(CRes.success == true){
        if(CRes.data && CRes.data[0] && CRes.data[0][0]){
          this.matDialog.open(BannerDisplayComponent);
        }
      }
    }catch(error){
      console.log(error);

    }
  })

  

  }

  scrollToID(id, speed){
    var offSet = 0;
    var targetOffset = $(id).offset().top - offSet;
    var mainNav = $('#main-nav');
    $('html,body').animate({scrollTop:targetOffset}, speed);
    if (mainNav.hasClass("open")) {
        mainNav.css("height", "1px").removeClass("in").addClass("collapse");
        mainNav.removeClass("open");
    }
}

langtranslator(lan)
  {              
    this.set_lang =lan; 
    sessionStorage.setItem('set_lang', lan);
    this.translate.use(lan);
    this.langEvent.next(lan);
  }
 
  redirectToLogin(){
    this.router.navigate(['/login'])
  }

}
