import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DDCService {

  constructor(private http: HttpClient) { }

  private BaseUrl = environment.BaseUrl;

  DDCFill():Observable<any>{
    return this.http.get<any>(this.BaseUrl + 'DDC/DDCFill');
  }

  ExcelExport(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'DDC/ExcelExport',Data);
  }

  DDCSave(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'DDC/DDCSave',Data);
  }


}
