import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";

import { TabBarService } from "../../Service/tab-bar.service";

import { DashboardComponent } from "../dashboard/dashboard.component";
import { DiamondSearchComponent } from "../diamond-search/diamond-search.component";
import { DiamondResultComponent } from "../diamond-result/diamond-result.component";
import { DiamondDetailComponent } from "../diamond-detail/diamond-detail.component";
import { DiamondCartComponent } from "../diamond-cart/diamond-cart.component";
import { PendingOrderComponent } from "../pending-order/pending-order.component";
import { OrderSummaryComponent } from "../order-summary/order-summary.component";
import { OrderSummaryDetailComponent } from "../order-summary-detail/order-summary-detail.component";
import { DiamondDdcComponent } from "../diamond-ddc/diamond-ddc.component";
import { ChangePassowrdComponent } from '../../AccountSetting/change-passowrd/change-passowrd.component';
import { EditProfileComponent } from '../../AccountSetting/edit-profile/edit-profile.component';

@Component({
  selector: "app-tab-bar",
  templateUrl: "./tab-bar.component.html",
  styleUrls: ["./tab-bar.component.css"]
})
export class TabBarComponent implements OnInit {
  ComponentName: any = {};
  selected = new FormControl(0);
  public dynamicTabs = [];

  constructor(public TabBarServ: TabBarService) {}

  ngOnInit(): void {

    let Obj1 = {
      label: "Diamond Search",
      component: DiamondSearchComponent
    };

    this.dynamicTabs.push(Obj1);

    this.TabBarServ.sharedData$.subscribe(sharedData => {
      window.scrollTo(0, 0);
      switch (sharedData) {
        case "Dashboard":
          this.ComponentName = DashboardComponent;
        break;
        case "Diamond Search":
          this.ComponentName = DiamondSearchComponent;
        break;
        case "Diamond Result":
          this.ComponentName = DiamondResultComponent;
        break;
        case "Stone Detail (" + localStorage.getItem("SD") + ")":
          this.ComponentName = DiamondDetailComponent;
        break;
        case "Diamond Result (" + localStorage.getItem("sctn") + ")":
          this.ComponentName = DiamondResultComponent;
        break;
        case "Cart":
          this.ComponentName = DiamondCartComponent;
        break;
        case "Pending Order":
          this.ComponentName = PendingOrderComponent;
        break;
        case "Confirmation Summary":
          this.ComponentName = OrderSummaryComponent;
        break;
        case "Confirmation Detail (" + localStorage.getItem("ID") + ")":
          this.ComponentName = OrderSummaryDetailComponent;
        break;
        case "DDC":
          this.ComponentName = DiamondDdcComponent;
        break;
        case "Change Password":
          this.ComponentName = ChangePassowrdComponent;
        break;
        case "Edit Profile":
        this.ComponentName = EditProfileComponent;
      break;
        
        default:
          // this.ComponentName = PartyMastComponent
        break;
      }

      let OpenTab;
      OpenTab = this.dynamicTabs.find(x => x.label == sharedData);

      if (OpenTab == undefined) {
        let Obj1 = {
          label: sharedData,
          component: this.ComponentName
        };

        this.dynamicTabs.push(Obj1);
        if (true) {
          this.selected.setValue(this.dynamicTabs.length - 1);
        }
      } else {
        const position = this.dynamicTabs.findIndex(PageName => {
          return PageName.label == sharedData;
        });

        this.selected.setValue(position);
      }
    });
  }

  removeTab(index: number) {
    this.dynamicTabs.splice(index, 1);
  }

  tabChanged(e: any) {
    this.TabBarServ.SetPageTitle(e.index == -1 ? "" : e.tab.textLabel);
  }
}
