import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  private BaseUrl = environment.BaseUrl;

  UserPanelDataCount(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'Dashboard/UserPanelDataCount',Data);
  }

  UserWiseCartList(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'Dashboard/UserWiseCartList' , Data);
  }

  OrderlistofPendingByUser(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'Dashboard/OrderlistofPendingByUser' , Data);
  }

  SliderStoneFill(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'Dashboard/SliderStoneFill' , Data);
  }

  SalesPersonFill(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'Dashboard/SalesPersonFill' , Data);
  }

  RMBRateFill(Data:any):Observable<any>{
    return this.http.post<any>(this.BaseUrl + 'Dashboard/RMBRateFill' , Data);
  }

}
