import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { DomSanitizer } from '@angular/platform-browser';

import { DiamondSearchService } from '../../Service/User/diamond-search/diamond-search.service';
import { TabBarService } from '../../Service/tab-bar.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-diamond-search",
  templateUrl: "./diamond-search.component.html",
  styleUrls: ["./diamond-search.component.css"]
})
export class DiamondSearchComponent implements OnInit {
  set_lang: any = '';
  StockView: FormGroup;

  ex3 = false;
  vg3 = false;
  gdofr = false;
  vgPlus3 = false;
  whiteColor = true;
  noBGM = false;
  selectedShadeValue = '';

  panelOpenState = false;

  locationList: any[] = [];
  fancyintensityList: any[] = [];
  fancyovertoneList: any[] = [];
  fancycolorList: any[] = [];
  shadeList: any[] = [];

  initObj = {
    shape: {
      selectAll: false,
      selectCount: 0,
      items: [
        {
          name: "Round",
          code: 3,
          selected: true,
          className: "icon-Round1"
        },
        {
          name: "Emerald",
          code: 7,
          selected: false,
          className: "icon-Emerald3"
        },
        {
          name: "Cushion",
          code: 6,
          selected: false,
          className: "icon-Cushion1"
        },
        {
          name: "Princess",
          code: 5,
          selected: false,
          className: "icon-ico_Princess"
        },
        {
          name: "Marquise",
          code: 11,
          selected: false,
          className: "icon-Marquise1"
        },
        {
          name: "Pear",
          code: 14,
          selected: false,
          className: "icon-Pear1"
        },
        {
          name: "Heart",
          code: 9,
          selected: false,
          className: "icon-Heart3"
        },
        {
          name: "Oval",
          code: 12,
          selected: false,
          className: "icon-Oval3"
        },
        {
          name: "SqEmerald",
          code: 8,
          selected: false,
          className: "icon-Square-Emerald1"
        },
        {
          name: "Radient",
          code: 16,
          selected: false,
          className: "icon-LgRadiant3"
        },
        {
          name: "Other Shapes",
          code: 1,
          selected: false,
          className: "icon-other"
        },
        {
          name: "Tapere",
          code: 28,
          selected: false,
          className: "icon-other"
        }
      ],
    },
    carat: {
      selectCount: 0,
      items: [

        { SizeRange: "0.30-0.39", selected: false, code: 3 },
        { SizeRange: "0.40-0.49", selected: false, code: 4 },
        { SizeRange: "0.50-0.69", selected: false, code: 5 },
        { SizeRange: "0.70-0.89", selected: false, code: 6 },
        { SizeRange: "0.90-0.99", selected: false, code: 7 },
        { SizeRange: "1.00-1.49", selected: false, code: 8 },
        { SizeRange: "1.50-1.99", selected: false, code: 9 },
        { SizeRange: "2.00-2.99", selected: false, code: 10 },
        { SizeRange: "3.00-4.99", selected: false, code: 11 },
        { SizeRange: "5.00-5.99", selected: false, code: 12 }
      ]
    },
    color: {
      selectAll: false,
      selectCount: 0,
      items: [
        { name: "D", code: 1, selected: false },
        { name: "E", code: 2, selected: false },
        { name: "F", code: 3, selected: false },
        { name: "G", code: 4, selected: false },
        { name: "H", code: 5, selected: false },
        { name: "I", code: 6, selected: false },
        { name: "J", code: 7, selected: false },
        { name: "K", code: 8, selected: false },
        { name: "L", code: 9, selected: false },
        { name: "M", code: 10, selected: false },
        { name: "N", code: 11, selected: false },
        { name: "O-Z", code: 96, selected: false },
      ],
    },
    clarity: {
      selectAll: false,
      items: [
        { name: "FL", code: 1, selected: false },
        { name: "IF", code: 3, selected: false },
        { name: "VVS1", code: 4, selected: false },
        { name: "VVS2", code: 5, selected: false },
        { name: "VS1 ", code: 6, selected: false },
        { name: "VS2", code: 7, selected: false },
        { name: "SI1", code: 8, selected: false },
        { name: "SI2", code: 9, selected: false },
        { name: "SI3", code: 10, selected: false },
        { name: "I1", code: 11, selected: false },
        { name: "I2", code: 15, selected: false },
        { name: "I3", code: 13, selected: false }
      ],
      selectCount: 0
    },
    cut: {
      selectAll: false,
      items: [
        { name: "EX", code: 1, selected: false },
        { name: "VG", code: 2, selected: false },
        { name: "GD", code: 3, selected: false },
        { name: "FR", code: 4, selected: false }
      ],
      selectCount: 0
    },
    pol: {
      selectAll: false,
      items: [
        { name: "EX", code: 1, selected: false },
        { name: "VG", code: 2, selected: false },
        { name: "GD", code: 4, selected: false },
        { name: "FR", code: 5, selected: false }
      ],
      selectCount: 0
    },
    symm: {
      selectAll: false,
      items: [
        { name: "EX", code: 1, selected: false },
        { name: "VG", code: 2, selected: false },
        { name: "GD", code: 3, selected: false },
        { name: "FR", code: 4, selected: false }
      ],
      selectCount: 0
    },
    fluorescence: {
      selectAll: false,
      items: [
        { name: "NONE", code: 1, selected: false },
        { name: "FAINT", code: 4, selected: false },
        { name: "MEDIUM", code: 2, selected: false },
        { name: "STRONG", code: 3, selected: false },
        { name: "VERY STRONG", code: 5, selected: false }
      ],
      selectCount: 0
    },
    eye: {
      selectAll: false,
      items: [
        { name: "Yes", code: 3, selected: false },
        { name: "No", code: 2, selected: false },
      ],
      selectCount: 0
    },
    luster: {
      selectAll: false,
      items: [
        { name: "EX", code: 1, selected: false },
        { name: "VG", code: 2, selected: false },
        { name: "ML1", code: 3, selected: false },
        { name: "ML2", code: 4, selected: false },
        { name: "ML3", code: 5, selected: false },
        { name: "G", code: 6, selected: false },
      ],
      selectCount: 0
    },
    ha: {
      selectAll: false,
      items: [
        { name: "EX", code: 1, selected: false },
        { name: "VG", code: 2, selected: false }
      ],
      selectCount: 0
    },
    lab: {
      selectAll: false,
      items: [
        { name: "GIA", code: 3, selected: false },
        { name: "HRD", code: 2, selected: false },
        { name: "IGI", code: 4, selected: false },
        { name: "SGS", code: 5, selected: false },
        { name: "AGS", code: 6, selected: false },
        { name: "EGL", code: 7, selected: false },
        { name: "IGC", code: 8, selected: false },
        { name: "IIDGR", code: 9, selected: false },
        { name: "NGTC", code: 10, selected: false },
        { name: "FM", code: 12, selected: false },
        { name: "GSI", code: 13, selected: false }
      ],
      selectCount: 0
    },
    shade:{
      selectAll: false,
      items: [],
      selectCount: 0
    }
  };

  constructor(
    private domSanitizer: DomSanitizer,
    public fb: FormBuilder,
    public DiamondSearchServ: DiamondSearchService,
    public TabBarServ: TabBarService,
    private translate: TranslateService
  ) {

    translate.addLangs(['en', 'ch']);
    translate.setDefaultLang('en');
    this.set_lang = sessionStorage.getItem('set_lang');
    if (this.set_lang == '' || this.set_lang == undefined || this.set_lang == null) {
      translate.setDefaultLang('en');
    } else {
      translate.setDefaultLang(this.set_lang);
    }


    this.StockView = this.fb.group({
      locationControl: [[]],

      fancyintensityControl: [[]],
      fancyovertoneControl: [[]],
      fancycolorControl: [[]],
      shadeControl: [[]],
      from_carat: null,
      to_carat: null,
      from_diamin: null,
      to_diamin: null,
      from_table: null,
      to_table: null,
      from_pavheight: null,
      to_pavheight: null,
      from_diamax: null,
      to_diamax: null,
      from_diaheight: null,
      to_diaheight: null,
      from_totaldepth: null,
      to_totaldepth: null,
      from_crownangle: null,
      to_crownangle: null,
      from_crownheight: null,
      to_crownheight: null,
      from_pavangle: null,
      to_pavangle: null,
      from_gridle: null,
      to_gridle: null,
      from_price: null,
      to_price: null,
      from_per: null,
      to_per: null,
      stoneid_list: null,
      certificate_list: null
    })
  }

  ngOnInit() {
    this.DiamondSearchServ.SearchMasterFill({}).subscribe(MastRes => {
      try {
        if (MastRes.success == 1) {

          let tempARy = MastRes.data[3].map(Inc => {
            return { name: Inc.Intensity, code: 1 };
          });

          tempARy.unshift({ name: "All", code: 0 });
          this.fancyintensityList = tempARy;

          let tempARyOv = MastRes.data[4].map(Ove => {
            return { name: Ove.Overton, code: 1 };
          });

          tempARyOv.unshift({ name: "All", code: 0 });
          this.fancyovertoneList = tempARyOv;

          let tempARyCol = MastRes.data[2].map(Col => {
            return { name: Col.FancyColour, code: 1 };
          });

          tempARyCol.unshift({ name: "All", code: 0 });
          this.fancycolorList = tempARyCol;

          let tempARyShd = MastRes.data[0].map(Shd => {
            return { name: Shd.Name, code: parseInt(Shd.id) };
          });

          // tempARyShd.unshift({ name: "All", code: 0 });
          this.shadeList = tempARyShd;

          let tempARyLoc = MastRes.data[1].map(Loc => {
            return { name: Loc.Name, code: parseInt(Loc.id) };
          });

          tempARyLoc.unshift({ name: "All", code: 0 });
          this.locationList = tempARyLoc;
          this.DaynemicSearchObject()
        } else {
        }
      } catch (error) {
        console.log(error);
      }
    });
  }

  DaynemicSearchObject(){
    // initObj 
    let ShadeItem = this.shadeList.map((item)=>{
      return {
        name: item.name, 
        code: item.code, 
        selected: false
      }
    });

    this.initObj.shade = {
    selectAll: false,
    selectCount: 0,
    items: ShadeItem
    } 
  }

  checkAll(bool, tarGet) {
    if (tarGet == "luster1") {
      // this.lustList = this.lustList.map(row => {
      //   return { name: row.name, code: row.code, selected: bool };
      // });
    } else if (tarGet == "hna1") {
      // this.hnaList = this.hnaList.map(row => {
      //   return { name: row.name, code: row.code, selected: bool };
      // });
    } else {
      if (!bool) {
        this.initObj[tarGet].selectCount = 0;
      }

      this.initObj[tarGet].items = this.initObj[tarGet].items.map(row => {
        if (tarGet == "shape") {
          return {
            name: row.name,
            code: row.code,
            selected: bool,
            className: row.className
          };
        } else {
          return { name: row.name, code: row.code, selected: bool };
        }
      });
    }
    if (!bool) {
      this.gdofr = false;
      this.ex3 = false;
      this.vg3 = false;
    }
  }

  onSubmit() {
    localStorage.setItem("SearchPara", JSON.stringify(this.createPostData()).toString())
    let ctn = localStorage.getItem('sctn')
    ctn = (parseInt(ctn) + 1).toString();
    localStorage.setItem("sctn", ctn)
    this.TabBarServ.setData("Diamond Result (" + ctn + ")");
  }

  unCheckAll(tarGet, TargetVal) {
    if (TargetVal != "All" && TargetVal.code != 0) {
      // tarGet == "shade" ||
      if (
        tarGet == "location" ||
        tarGet == "fancyintensity" ||
        tarGet == "fancycolor" ||
        tarGet == "fancyovertone"
      ) {
        let AllFilter = this[tarGet + "List"].filter(item => item.code != 0);
        let selectedFilter = this.StockView.value[tarGet + "Control"].filter(
          item => item.code != 0
        );

        if (AllFilter.length == selectedFilter.length) {
          this.StockView.patchValue({
            [tarGet + "Control"]: [
              {
                name: "All",
                code: 0
              },
              ...this.StockView.value[tarGet + "Control"]
            ]
          });

          this["all" + tarGet + "Selected"] = true;
        } else if (AllFilter.length > selectedFilter.length) {
          this["all" + tarGet + "Selected"] = false;
          this.StockView.patchValue({
            [tarGet + "Control"]: this.StockView.value[
              tarGet + "Control"
            ].filter(item => item.code != 0)
          });
        }
      } else if (tarGet == "lust1") {
        // this.allLustSelected = !this.lustList.some(item => !item.selected);
      } else if (tarGet == "hna") {
        // this.allHNASelected = !this.hnaList.some(item => !item.selected);
      } else {
        //check if atlest one item is not selected
        this.initObj[tarGet].selectAll = !this.initObj[tarGet].items.some(
          item => !item.selected
        );
      }
    }
  }



  selectSame(Common) {
    this.initObj.cut.items.forEach(function (item) {
      item.selected = false;
    });

    this.initObj.pol.items.forEach(function (item) {
      item.selected = false;
    });

    this.initObj.symm.items.forEach(function (item) {
      item.selected = false;
    });

    if (Common == "3ex") {
      this.ex3 = !this.ex3;
      this.vgPlus3 = false;
      this.vg3 = false;
      this.gdofr = false;
      this.initObj.cut.items = this.initObj.cut.items.map(item =>
        item.name == "EX"
          ? {
            name: item.name,
            code: item.code,
            selected: this.ex3
          }
          : item
      );
      this.initObj.pol.items = this.initObj.pol.items.map(item =>
        item.name == "EX"
          ? {
            name: item.name,
            code: item.code,
            selected: this.ex3
          }
          : item
      );
      this.initObj.symm.items = this.initObj.symm.items.map(item =>
        item.name == "EX"
          ? {
            name: item.name,
            code: item.code,
            selected: this.ex3
          }
          : item
      );
    } else if (Common == "3vg") {
      this.vg3 = !this.vg3;
      this.ex3 = false;
      this.vgPlus3 = false;
      this.gdofr = false;
      this.initObj.cut.items = this.initObj.cut.items.map(item =>
        item.name == "VG"
          ? {
            name: item.name,
            code: item.code,
            selected: this.vg3
          }
          : item
      );
      this.initObj.pol.items = this.initObj.pol.items.map(item =>
        item.name == "VG"
          ? {
            name: item.name,
            code: item.code,
            selected: this.vg3
          }
          : item
      );
      this.initObj.symm.items = this.initObj.symm.items.map(item =>
        item.name == "VG"
          ? {
            name: item.name,
            code: item.code,
            selected: this.vg3
          }
          : item
      );
    } else if (Common == "gdofr") {
      this.gdofr = !this.gdofr;
      this.vg3 = false;
      this.ex3 = false;
      this.vgPlus3 = false;
      this.initObj.cut.items = this.initObj.cut.items.map(item =>
        item.name == "GD" || item.name == "FR"
          ? {
            name: item.name,
            code: item.code,
            selected: this.gdofr
          }
          : item
      );
      this.initObj.pol.items = this.initObj.pol.items.map(item =>
        item.name == "GD" || item.name == "FR"
          ? {
            name: item.name,
            code: item.code,
            selected: this.gdofr
          }
          : item
      );
      this.initObj.symm.items = this.initObj.symm.items.map(item =>
        item.name == "GD" || item.name == "FR"
          ? {
            name: item.name,
            code: item.code,
            selected: this.gdofr
          }
          : item
      );
    } else if (Common == "3vgPlus") {
      this.vgPlus3 = !this.vgPlus3;
      this.gdofr = false;
      this.vg3 = false;
      this.ex3 = false;

      this.initObj.cut.items = this.initObj.cut.items.map(item =>
        item.name == "EX" || item.name == "VG"
          ? {
            name: item.name,
            code: item.code,
            selected: this.vgPlus3
          }
          : item
      );
      this.initObj.pol.items = this.initObj.pol.items.map(item =>
        item.name == "EX" || item.name == "VG"
          ? {
            name: item.name,
            code: item.code,
            selected: this.vgPlus3
          }
          : item
      );
      this.initObj.symm.items = this.initObj.symm.items.map(item =>
        item.name == "EX" || item.name == "VG"
          ? {
            name: item.name,
            code: item.code,
            selected: this.vgPlus3
          }
          : item
      );
    }

    this.initObj["cut"].selectAll = !this.initObj["cut"].items.some(
      item => !item.selected
    );
    this.initObj["symm"].selectAll = !this.initObj["symm"].items.some(
      item => !item.selected
    );
    this.initObj["pol"].selectAll = !this.initObj["pol"].items.some(
      item => !item.selected
    );

  }

  SelectNoBGM(Common) {
    this.noBGM = !this.noBGM;
    console.log(this.noBGM);

    this.initObj.luster.items.forEach(function (item) {
      item.selected = false;
    });

    this.shadeList.forEach(function (item) {
      item.selected = false;

    });

    this.initObj.luster.items = this.initObj.luster.items.map(item =>
      item.name == "EX"
        ? {
          name: item.name,
          code: item.code,
          selected: this.noBGM
        }
        : item
    );

    // for (let item of this.shadeList) {
    //   if (item.name == "NONE") {
    //     item.selected = this.noBGM;
    //     this.selectSingleChipOption(item.name,'shade');

    //   }
    // }

    this.initObj.shade.items = this.initObj.shade.items.map(item =>
      item.name == "NONE"
        ? {
          name: item.name,
          code: item.code,
          selected: this.noBGM
        }
        : item
    );

  }

  keyPressNumbersWithDecimal(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }




  createPostData() {
    let finalPostData = {},
      FinalLocationPost,
      FinalShadePost;
    let finalColorPost = "";

    let commaJoiner = TargeT => {
      return this.initObj[TargeT].items
        .filter(item => item.selected)
        .map(item => item.code)
        .join(",");
    };
    let [
      finalcolorPost,
      finalclarityPost,
      finalfluorescencePost,
      finalcutPost,
      finallabPost,
      finalsymmPost,
      finalpolPost,
      finalShapePost,
      finalSizePost,
      finalEyePost,
      finalHAPost,
      finalLusterPost,
      finalshadePost
    ] = [
        commaJoiner("color"),
        commaJoiner("clarity"),
        commaJoiner("fluorescence"),
        commaJoiner("cut"),
        commaJoiner("lab"),
        commaJoiner("symm"),
        commaJoiner("pol"),
        commaJoiner("shape"),
        commaJoiner("carat"),
        commaJoiner("eye"),
        commaJoiner("ha"),
        commaJoiner("luster"),
        commaJoiner("shade")
      ];
    finalPostData["shape_list"] = finalShapePost;
    finalPostData["from_carat"] = this.StockView.value.from_carat ? this.StockView.value.from_carat : 0;
    finalPostData["to_carat"] = this.StockView.value.to_carat ? this.StockView.value.to_carat : 0;
    finalPostData["stoneid_list"] = this.StockView.value.stoneid_list ? this.StockView.value.stoneid_list.split(/[\n]+/).toString().replace('\n', ',') : "";
    finalPostData["certificate_list"] = this.StockView.value.certificate_list ? this.StockView.value.certificate_list.split(/[\n]+/).toString().replace('\n', ',') : "";
    finalPostData["size_list"] = finalSizePost;
    finalPostData["shade_list"] = finalshadePost;
    let _DiamondType = this.whiteColor == true ? 0 : 1
    finalPostData["DiamondType"] = _DiamondType;

    if (this.whiteColor) {
      finalPostData["color_list"] = finalcolorPost;
    } else {
      finalPostData["fancyintensity"] = this.StockView.value.fancyintensityControl
        .map(item => item.name)
        .filter(item => item.toUpperCase() != "ALL")
        .join(",");
      finalPostData["fancyovertone"] = this.StockView.value.fancyovertoneControl
        .map(item => item.name)
        .filter(item => item.toUpperCase() != "ALL")
        .join(",");
      finalPostData["fancycolor"] = this.StockView.value.fancycolorControl
        .map(item => item.name)
        .filter(item => item.toUpperCase() != "ALL")
        .join(",");
    }

    if (this.StockView.value.shadeControl.length > 1) {
      FinalShadePost = this.StockView.value.shadeControl.reduce(
        (commaSep, Elem) => {
          if (typeof commaSep === "object") {
            return parseInt(commaSep.code) == 0
              ? Elem.code
              : commaSep.code + "," + Elem.code;
          } else {
            return commaSep + "," + Elem.code;
          }
        }
      );
    } else {
      FinalShadePost =
        this.StockView.value.shadeControl.length == 0
          ? ""
          : this.StockView.value.shadeControl[0].code;
    }


    finalPostData["clarity_list"] = finalclarityPost;
    finalPostData["cut_list"] = finalcutPost;
    finalPostData["polish_list"] = finalpolPost;
    finalPostData["symm_list"] = finalsymmPost;
    finalPostData["flo_list"] = finalfluorescencePost;
    finalPostData["eyeclean_list"] = finalEyePost;
    finalPostData["from_price"] = this.StockView.value.from_price ? this.StockView.value.from_price : 0;
    finalPostData["to_price"] = this.StockView.value.to_price ? this.StockView.value.to_price : 0;
    finalPostData["from_per"] = this.StockView.value.from_per ? this.StockView.value.from_per : 0;
    finalPostData["to_per"] = this.StockView.value.to_per ? this.StockView.value.to_per : 0;
    finalPostData["ha_list"] = finalHAPost;
    finalPostData["luster_list"] = finalLusterPost;


    if (this.StockView.value.locationControl.length > 1) {
      FinalLocationPost = this.StockView.value.locationControl.reduce(
        (commaSep, Elem) => {
          if (typeof commaSep === "object") {
            return parseInt(commaSep.code) == 0
              ? Elem.code
              : commaSep.code + "," + Elem.code;
          } else {
            return commaSep + "," + Elem.code;
          }
        }
      );
    } else {
      FinalLocationPost =
        this.StockView.value.locationControl.length == 0
          ? ""
          : this.StockView.value.locationControl[0].code;
    }

    finalPostData["location_list"] = FinalLocationPost;
    finalPostData["cert_list"] = finallabPost;

    finalPostData["from_diamin"] = this.StockView.value.from_diamin ? this.StockView.value.from_diamin : 0;
    finalPostData["to_diamin"] = this.StockView.value.to_diamin ? this.StockView.value.to_diamin : 0;

    finalPostData["from_diamax"] = this.StockView.value.from_diamax ? this.StockView.value.from_diamax : 0;
    finalPostData["to_diamax"] = this.StockView.value.to_diamax ? this.StockView.value.to_diamax : 0;

    finalPostData["from_diaheight"] = this.StockView.value.from_diaheight ? this.StockView.value.from_diaheight : 0;
    finalPostData["to_diaheight"] = this.StockView.value.to_diaheight ? this.StockView.value.to_diaheight : 0;

    finalPostData["from_totaldepth"] = this.StockView.value.from_totaldepth ? this.StockView.value.from_totaldepth : 0;
    finalPostData["to_totaldepth"] = this.StockView.value.to_totaldepth ? this.StockView.value.to_totaldepth : 0;

    finalPostData["from_table"] = this.StockView.value.from_table ? this.StockView.value.from_table : 0;
    finalPostData["to_table"] = this.StockView.value.to_table ? this.StockView.value.to_table : 0;

    finalPostData["from_crownangle"] = this.StockView.value.from_crownangle ? this.StockView.value.from_crownangle : 0;
    finalPostData["to_crownangle"] = this.StockView.value.to_crownangle ? this.StockView.value.to_crownangle : 0;

    finalPostData["from_crownheight"] = this.StockView.value.from_crownheight ? this.StockView.value.from_crownheight : 0;
    finalPostData["to_crownheight"] = this.StockView.value.to_crownheight ? this.StockView.value.to_crownheight : 0;

    finalPostData["from_pavangle"] = this.StockView.value.from_pavangle ? this.StockView.value.from_pavangle : 0;
    finalPostData["to_pavangle"] = this.StockView.value.to_pavangle ? this.StockView.value.to_pavangle : 0;

    finalPostData["from_pavheight"] = this.StockView.value.from_pavheight ? this.StockView.value.from_pavheight : 0;
    finalPostData["to_pavheight"] = this.StockView.value.to_pavheight ? this.StockView.value.to_pavheight : 0;

    finalPostData["from_gridle"] = this.StockView.value.from_gridle ? this.StockView.value.from_gridle : 0;
    finalPostData["to_gridle"] = this.StockView.value.to_gridle ? this.StockView.value.to_gridle : 0;

    return finalPostData;

  }


  compareObjects(o1: any, o2: any): boolean {
    return o1.name === o2.name && o1._id === o2._id;
  }

  selectSingleChipOption(valueObj, Target) {
    if (valueObj == "All" || valueObj.code == 0) {
      if (this["all" + Target + "Selected"]) {
        this.StockView.controls[Target + "Control"].setValue([]);
      } else {
        this.StockView.controls[Target + "Control"].setValue(
          this[Target + "List"]
        );
      }
      this["all" + Target + "Selected"] = !this["all" + Target + "Selected"];
    }
  }

  resetForm() {
    this.ex3 = false;
    this.vg3 = false;
    this.gdofr = false;

    this.initObj = {
      shape: {
        selectAll: false,
        selectCount: 0,
        items: [
          {
            name: "Round",
            code: 3,
            selected: true,
            className: "icon-Round1"
          },
          {
            name: "Emerald",
            code: 7,
            selected: false,
            className: "icon-Emerald3"
          },
          {
            name: "Cushion",
            code: 6,
            selected: false,
            className: "icon-Cushion1"
          },
          {
            name: "Princess",
            code: 5,
            selected: false,
            className: "icon-ico_Princess"
          },
          {
            name: "Marquise",
            code: 11,
            selected: false,
            className: "icon-Marquise1"
          },
          {
            name: "Pear",
            code: 14,
            selected: false,
            className: "icon-Pear1"
          },
          {
            name: "Heart",
            code: 9,
            selected: false,
            className: "icon-Heart3"
          },
          {
            name: "Oval",
            code: 12,
            selected: false,
            className: "icon-Oval3"
          },
          {
            name: "SqEmerald",
            code: 8,
            selected: false,
            className: "icon-Square-Emerald1"
          },
          {
            name: "Radient",
            code: 16,
            selected: false,
            className: "icon-LgRadiant3"
          },
          {
            name: "Other Shapes",
            code: 1,
            selected: false,
            className: "icon-other"
          }
        ],
      },
      carat: {
        selectCount: 0,
        items: [
          { SizeRange: "0.30-0.39", selected: false, code: 3 },
          { SizeRange: "0.40-0.49", selected: false, code: 4 },
          { SizeRange: "0.50-0.69", selected: false, code: 5 },
          { SizeRange: "0.70-0.89", selected: false, code: 6 },
          { SizeRange: "0.90-0.99", selected: false, code: 7 },
          { SizeRange: "1.00-1.49", selected: false, code: 8 },
          { SizeRange: "1.50-1.99", selected: false, code: 9 },
          { SizeRange: "2.00-2.99", selected: false, code: 10 },
          { SizeRange: "3.00-4.99", selected: false, code: 11 },
          { SizeRange: "5.00-5.99", selected: false, code: 12 }
        ]
      },
      color: {
        selectAll: false,
        selectCount: 0,
        items: [
          { name: "D", code: 1, selected: false },
          { name: "E", code: 2, selected: false },
          { name: "F", code: 3, selected: false },
          { name: "G", code: 4, selected: false },
          { name: "H", code: 5, selected: false },
          { name: "I", code: 6, selected: false },
          { name: "J", code: 7, selected: false },
          { name: "K", code: 8, selected: false },
          { name: "L", code: 9, selected: false },
          { name: "M", code: 10, selected: false },
          { name: "N", code: 11, selected: false },
          { name: "O-Z", code: 96, selected: false },
        ],
      },
      clarity: {
        selectAll: false,
        items: [
          { name: "FL", code: 1, selected: false },
          { name: "IF", code: 3, selected: false },
          { name: "VVS1", code: 4, selected: false },
          { name: "VVS2", code: 5, selected: false },
          { name: "VS1 ", code: 6, selected: false },
          { name: "VS2", code: 7, selected: false },
          { name: "SI1", code: 8, selected: false },
          { name: "SI2", code: 9, selected: false },
          { name: "SI3", code: 10, selected: false },
          { name: "I1", code: 11, selected: false },
          { name: "I2", code: 15, selected: false },
          { name: "I3", code: 13, selected: false }
        ],
        selectCount: 0
      },
      cut: {
        selectAll: false,
        items: [
          { name: "EX", code: 1, selected: false },
          { name: "VG", code: 2, selected: false },
          { name: "GD", code: 3, selected: false },
          { name: "FR", code: 4, selected: false }
        ],
        selectCount: 0
      },
      pol: {
        selectAll: false,
        items: [
          { name: "EX", code: 1, selected: false },
          { name: "VG", code: 2, selected: false },
          { name: "GD", code: 4, selected: false },
          { name: "FR", code: 5, selected: false }
        ],
        selectCount: 0
      },
      symm: {
        selectAll: false,
        items: [
          { name: "EX", code: 1, selected: false },
          { name: "VG", code: 2, selected: false },
          { name: "GD", code: 3, selected: false },
          { name: "FR", code: 4, selected: false }
        ],
        selectCount: 0
      },
      fluorescence: {
        selectAll: false,
        items: [
          { name: "NONE", code: 1, selected: false },
          { name: "FAINT", code: 4, selected: false },
          { name: "MEDIUM", code: 2, selected: false },
          { name: "STRONG", code: 3, selected: false },
          { name: "VERY STRONG", code: 5, selected: false }
        ],
        selectCount: 0
      },
      eye: {
        selectAll: false,
        items: [
          { name: "Yes", code: 3, selected: false },
          { name: "No", code: 2, selected: false },
        ],
        selectCount: 0
      },
      luster: {
        selectAll: false,
        items: [
          { name: "EX", code: 1, selected: false },
          { name: "VG", code: 2, selected: false },
          { name: "ML1", code: 3, selected: false },
          { name: "ML2", code: 4, selected: false },
          { name: "ML3", code: 5, selected: false },
          { name: "G", code: 6, selected: false },
        ],
        selectCount: 0
      },
      ha: {
        selectAll: false,
        items: [
          { name: "EX", code: 1, selected: false },
          { name: "VG", code: 2, selected: false }
        ],
        selectCount: 0
      },
      lab: {
        selectAll: false,
        items: [
          { name: "GIA", code: 3, selected: false },
          { name: "HRD", code: 2, selected: false },
          { name: "IGI", code: 4, selected: false },
          { name: "SGS", code: 5, selected: false },
          { name: "AGS", code: 6, selected: false },
          { name: "EGL", code: 7, selected: false },
          { name: "IGC", code: 8, selected: false },
          { name: "IIDGR", code: 9, selected: false },
          { name: "NGTC", code: 10, selected: false },
          { name: "FM", code: 12, selected: false },
          { name: "GSI", code: 13, selected: false }
        ],
        selectCount: 0
      },
      shade:{
        selectAll: false,
        items: [],
        selectCount: 0
      }
    };
    this.DaynemicSearchObject()

    this.StockView.patchValue({
      locationControl: [[]],

      fancyintensityControl: [[]],
      fancyovertoneControl: [[]],
      fancycolorControl: [[]],
      shadeControl: [[]],
      from_carat: null,
      to_carat: null,
      from_diamin: null,
      to_diamin: null,
      from_table: null,
      to_table: null,
      from_pavheight: null,
      to_pavheight: null,
      from_diamax: null,
      to_diamax: null,
      from_diaheight: null,
      to_diaheight: null,
      from_totaldepth: null,
      to_totaldepth: null,
      from_crownangle: null,
      to_crownangle: null,
      from_crownheight: null,
      to_crownheight: null,
      from_pavangle: null,
      to_pavangle: null,
      from_gridle: null,
      to_gridle: null,
      from_price: null,
      to_price: null,
      from_per: null,
      to_per: null,
      stoneid_list: null,
      certificate_list: null
    });

  }
}
