import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { ForgotPasswordService } from '../Service/forgot-password.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  ForgotPassForm: FormGroup;


  constructor(
    private ForgotPasswordServ:ForgotPasswordService,
    private router:Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.ForgotPassForm = new FormGroup({
      UserName: new FormControl("", [Validators.required]),
      Email: new FormControl("", [Validators.required])
    });
  }

  onSubmit(){
    this.spinner.show();
    this.ForgotPasswordServ.ForgotPasswordUpdate({UserName: this.ForgotPassForm.value.UserName,Email:this.ForgotPassForm.value.Email}).subscribe((FRes)=>{
      try{
        if(FRes.success == 1){
          this.spinner.hide()
          this.ForgotPassForm.reset()
          this.toastr.success('Password forgot successfully')
        }else if(FRes.success == 2){
          this.spinner.hide()
          this.toastr.warning("User or email address is not found")
        }else{
          this.spinner.hide()
          this.toastr.warning("Someting gone worng while forgot-password")
        }
      }catch(error){
        this.spinner.hide()
        this.toastr.error(error)
      }
    })
  }

  closeLogin(){
    this.router.navigate(['/index'])
  }

}
