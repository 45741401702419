import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { DatePipe } from '@angular/common'

import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { RegisterService } from './../Service/register.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  RefShowFlg:boolean=true;

  CountryArr=[]
  StateArr=[]
  CityArr=[]

  UserName:any=''
  Password:any=''
  C_Password:any=''
  Title:any=''
  FirstName:any=''
  MiddleName:any=''
  LastName:any=''
  Designation:any=''
  BirthDate:any=''
  PhoneNo:any=''
  AlternativePhoneNo:any=''
  Email:any=''
  AlternativeEmail:any=''
  Fax:any=''
  SkypeId:any=''
  QQId:any=''
  CompanyName:any=''
  CompanyAddress:any=''
  C_CityID:any=''
  C_StateID:any=''
  C_CountryID:any=''
  C_PostalCode:any=''
  TC:boolean=false
  PassportNO:any=''

  constructor(
    private router:Router,
    private RegisterServ : RegisterService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private datePipe : DatePipe
  ) { }

  ngOnInit() {
    this.CountryFill()
  }

  CountryFill(){
    this.RegisterServ.CountyFill({}).subscribe((CRes)=>{
      try{
        if(CRes.success == true){
          this.CountryArr = CRes.data.map((item)=>{
            return {code:item.CountryID,value:item.CountryName}
          })
        }
      }catch(error){
        console.log(error);

      }
    })
  }

  StateFill(){
    this.RegisterServ.StateFill({CountryID:this.C_CountryID}).subscribe((SRes)=>{
      try {
        if(SRes.success == true){
          this.StateArr = SRes.data.map((item)=>{
            return {code:item.StateID,value:item.StateName}
          })
        }
      } catch (error) {
        console.log(error)
      }
    })
  }

  CityFill(){
    this.RegisterServ.CityFill({StateID:this.C_StateID}).subscribe((CRes)=>{
      try {
        if(CRes.success == true){
          this.CityArr = CRes.data.map((item)=>{
            return {code:item.CityID,value:item.CityName}
          })
        }
      } catch (error) {
        console.log(error)
      }
    })
  }

  closeRegistration(){
    this.router.navigate(['/index'])
  }

  Reset(){
    this.StateArr=[]
    this.CityArr=[]

    this.UserName=''
    this.Password=''
    this.C_Password=''
    this.Title=''
    this.FirstName=''
    this.MiddleName=''
    this.LastName=''
    this.Designation=''
    this.BirthDate=''
    this.PhoneNo=''
    this.AlternativePhoneNo=''
    this.Email=''
    this.AlternativeEmail=''
    this.Fax=''
    this.SkypeId=''
    this.QQId=''
    this.CompanyName=''
    this.CompanyAddress=''
    this.C_CityID=''
    this.C_StateID=''
    this.C_CountryID=''
    this.C_PostalCode=''
    this.TC=false
    this.PassportNO = ''
  }

  Save(){
    if(this.FirstName.trim() == ""){
      this.toastr.warning("Enter First Name");
      return;
    }
    if(this.LastName.trim() == ""){
      this.toastr.warning("Enter Last Name");
      return;
    }
    if(this.UserName.trim() == ""){
      this.toastr.warning("Enter User Name");
      return;
    }
    if(this.Password == ""){
      this.toastr.warning("Enter Password");
      return;
    }
    if(this.C_Password == ""){
      this.toastr.warning("Enter Confirm Password");
      return;
    }
    if(this.Email.trim() == ""){
      this.toastr.warning("Enter Email");
      return;
    }
    if(this.PhoneNo.trim() == ""){
      this.toastr.warning("Enter Phone Number");
      return;
    }
    if(this.Password != this.C_Password){
      this.toastr.warning("Password not match.");
      return;
    }

    let SaveObj={
      UserName:this.UserName.trim(),
      Password:this.C_Password,
      Title:this.Title ? this.Title : '',
      FirstName:this.FirstName,
      MiddleName:this.MiddleName ? this.MiddleName : '',
      LastName:this.LastName,
      Designation:this.Designation,
      BirthDate:this.BirthDate ? this.datePipe.transform(this.BirthDate ,'yyyy-MM-dd'):'',
      PhoneNo:this.PhoneNo,
      AlternativePhoneNo:this.AlternativePhoneNo ? this.AlternativePhoneNo : '' ,
      Email:this.Email,
      AlternativeEmail: this.AlternativeEmail? this.AlternativeEmail : '',
      Fax:this.Fax ?  this.Fax : '',
      SkypeId: this.SkypeId ? this.SkypeId : '',
      QQId: this.QQId ? this.QQId : '',
      CompanyName : this.CompanyName ? this.CompanyName : '',
      CompanyAddress:this.CompanyAddress ? this.CompanyAddress : '',
      C_CityID :this.C_CityID,
      C_StateID: this.C_StateID,
      C_CountryID:this.C_CountryID,
      C_PostalCode:this.C_PostalCode,
      PassportNO:this.PassportNO ? this.PassportNO : ''
    }
    this.spinner.show()
    this.RegisterServ.RegistrationSave(SaveObj).subscribe((SRes)=>{
      try{
        if(SRes.success == true){
          this.spinner.hide()
          if(SRes.data[0].USERID){
            let MediaObj={
              UserID:SRes.data[0].USERID,
              Skype:this.SkypeId ? this.SkypeId : '',
              QQID:this.QQId ? this.QQId : ''
            }
            this.Reset()
            this.RegisterServ.SocialMediaInsertByUser(MediaObj).subscribe((MRes)=>{
              try {

              } catch (error) {
                console.log(error)
              }
            })
          }
        }else{
          this.spinner.hide()
          this.toastr.warning('Something gone wrong while registration')
        }
      }catch(err){
        this.spinner.hide()
        this.toastr.error(err)
      }
    })
  }

  CheckUserName(){
    if(this.UserName.trim()!=""){
      this.spinner.show()
      this.RegisterServ.UserNameCheck({UserName:this.UserName.trim()}).subscribe((CRes)=>{
        try{
          this.spinner.hide()
          if(CRes.success == true){
            if(CRes.data[0].USERAVAIL == "1"){
              this.toastr.warning("Select different user name")
              this.UserName = ''
              return
            }
          }else{
            this.toastr.warning("Something gone wrong while check user name")
          }
        }catch(err){
          this.spinner.hide()
          this.toastr.error(err)
        }
      })
    }

  }
}
