import { Component, OnInit } from '@angular/core';

import { ChangePassowrdService } from '../../Service/AccountSetting/change-passowrd.service';

import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-passowrd',
  templateUrl: './change-passowrd.component.html',
  styleUrls: ['./change-passowrd.component.css']
})
export class ChangePassowrdComponent implements OnInit {

  DisChngPassBtn:boolean=false

  OldPass:any=""
  NewPass:any=""
  ReEntPass:any=""

  constructor(
    private ChangePassowrdServ : ChangePassowrdService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
  }

  MatchPass(){
    if(this.NewPass == this.ReEntPass){
      this.DisChngPassBtn = true
    }else{
      this.DisChngPassBtn = false
    }
  }

  ChangePass(){
    if(this.OldPass == ""){
      this.toastr.warning("Enter old passowrd.")
      return
    }
    if(this.NewPass == ""){
      this.toastr.warning("Enter new passowrd.")
      return
    }
    if(this.ReEntPass == ""){
      this.toastr.warning("Enter re-enter passowrd.")
      return
    }
    this.spinner.show()
    this.ChangePassowrdServ.CheckOldPassword({password:this.OldPass}).subscribe((COPRes)=>{
      try{
        if(COPRes.success == 1){

          if(COPRes.data[0].result == "1"){
            this.ChangePassowrdServ.ChangePasswordsave({password:this.ReEntPass}).subscribe((NPRes)=>{
              try{
                if(NPRes.success == 1){
                  this.spinner.hide()
                  this.toastr.success("Change passowrd successfully")
                  this.OldPass = ""
                  this.NewPass = ""
                  this.ReEntPass = ""
                  this.DisChngPassBtn  = false
                }else{
                  this.spinner.hide()
                  this.toastr.warning("something gone wrong while change password")
                }
              }catch(error){
                this.spinner.hide()
                this.toastr.error(error)
              }
            })
          }else{
            this.spinner.hide()
            this.toastr.warning("Old password is wrong.")
          }
        }else{
          this.spinner.hide()
          this.toastr.warning("something gone wrong while check old password")
        }
      }catch(error){
        this.spinner.hide()
        this.toastr.error(error)
      }
    })
  }

}
