import { Component, OnInit } from '@angular/core';
import { LoginService } from './../Service/login.service';
@Component({
  selector: 'app-banner-display',
  templateUrl: './banner-display.component.html',
  styleUrls: ['./banner-display.component.css']
})
export class BannerDisplayComponent implements OnInit {
 imageUrl : any = '';
  constructor(
    private LoginServ : LoginService,
  ) { }

  ngOnInit() {
    this.LoginServ.GetBannerDetail().subscribe((CRes)=>{
      try{
        if(CRes.success == true){
          if(CRes.data && CRes.data[0] && CRes.data[0][0]){
          this.imageUrl ='https://selenediam.com/Uploads/BannerImages/'+  CRes.data[0][0].ImageURL
          }
        }
      }catch(error){
        console.log(error);

      }
    })
    
    //this.imageUrl ="../../assets/img/KASEAdv.jpeg";
    //this.imageUrl ="../../assets/img/diwali-notice.jpg";
  }

}
