import { Component, OnInit } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-diamond-detail',
  templateUrl: './diamond-detail.component.html',
  styleUrls: ['./diamond-detail.component.css']
})
export class DiamondDetailComponent implements OnInit {

  StockNo:any=''
  StockDet:any=[]

  videoUrl: any;
  certiUrl: any;
  imageUrl: any;
  disPlay = 'image';
  certiIsImage = true;
  set_lang:any='';

  constructor(
    private sanitizer: DomSanitizer,
    private translate: TranslateService
  ) { 
    translate.addLangs(['en', 'ch']);
    translate.setDefaultLang('en');
    this.set_lang=sessionStorage.getItem('set_lang');
    if(this.set_lang == '' || this.set_lang == undefined || this.set_lang == null)
    {
      translate.setDefaultLang('en');
    }else{
      translate.setDefaultLang(this.set_lang);
    }
  }

  ngOnInit() {
    this.StockNo = localStorage.getItem("SD");
    this.StockDet = JSON.parse(localStorage.getItem(this.StockNo))
    
    this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.StockDet.ImageUrl);
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.StockDet.VideoUrl);
    this.certiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.StockDet.CertPDFUrl);
    
    if(this.StockDet.CertPDFUrl){
      let certiExt = this.StockDet.CertPDFUrl.split('.').pop();
      if(certiExt.toUpperCase()=='PDF'){
        this.certiIsImage = false;
      }
    }


    localStorage.removeItem("SD")
    localStorage.removeItem(this.StockNo)
  }

  tofixedto2(elm){

    if(elm!=''){
      return parseFloat(elm).toFixed(2)
    }else{
      return '';
    }

  }

}
