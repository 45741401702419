import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { LoginService } from '../Service/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  isGuestLogin:any = false;
  constructor(
    private loginServ:LoginService,
    private router:Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {
    this.loginForm = new FormGroup({
      UserId: new FormControl("", [Validators.required]),
      password: new FormControl("", [Validators.required])
    });
   }

  ngOnInit() {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('indexContainer');
    localStorage.removeItem('isGuestLogin');

    if(localStorage.getItem('token')){
      this.router.navigateByUrl('home');
    }else{
      this.router.navigateByUrl('login');
    }
  }

  onSubmit(){
    this.spinner.show()
    this.loginServ.Login({login_name:this.loginForm.value.UserId,password:this.loginForm.value.password,app_code:"1" }).subscribe(LoginRes => {
      try{
        if(LoginRes.success == 1){
          this.spinner.hide();
          localStorage.setItem('token',LoginRes.data)
          this.isGuestLogin = false;
          localStorage.setItem('isGuestLogin',this.isGuestLogin)
          localStorage.setItem('sctn','0')
          this.router.navigateByUrl('home');
        }else if(LoginRes.success == 2){
          this.spinner.hide()
          this.toastr.info(LoginRes.data)
        }else{
          this.spinner.hide()
          this.toastr.info(LoginRes.data)
        }
      }catch(error){
        this.spinner.hide()
        this.toastr.error(error)
      }
    })
  }

  guestLogin(){
    
    this.spinner.show()
    this.loginServ.Login({login_name:'guest',password:'guest',app_code:"1" }).subscribe(LoginRes => {
      try{
        if(LoginRes.success == 1){
          this.spinner.hide();
          localStorage.setItem('token',LoginRes.data)
          localStorage.setItem('sctn','0')
          this.isGuestLogin  = true;
          localStorage.setItem('isGuestLogin',this.isGuestLogin)
          this.router.navigateByUrl('home');
        }else if(LoginRes.success == 2){
          this.spinner.hide()
          this.toastr.info(LoginRes.data)
        }else{
          this.spinner.hide()
          this.toastr.info(LoginRes.data)
        }
      }catch(error){
        this.spinner.hide()
        this.toastr.error(error)
      }
    })
  }

  closeLogin(){
    this.router.navigate(['/index'])
  }

  redirectToRegistration(){
    this.router.navigate(['/register'])
  }
  
  redirectToForgotPass(){
    this.router.navigate(['/forgotpassword'])
  }
}
