import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { OrderService } from "../../Service/User/diamond-order/order.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ImgVideoComponent } from "../img-video/img-video.component";
import { TabBarService } from "../../Service/tab-bar.service";
import {TranslateService} from '@ngx-translate/core';
import { ICellRendererParams } from 'ag-grid-community';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.css']
})
export class OrderSummaryComponent implements OnInit {

  decodeHelper = new JwtHelperService();
  decodedTkn = this.decodeHelper.decodeToken(localStorage.getItem('token'));

  apiUrl = environment.BaseUrl;


  public columnDefs;
  public gridApi;
  public gridColumnApi;
  public defaultColDef;
  public rowSelection;
  public isRowSelectable;
  selectedRow = [];
  set_lang = "";

  constructor(
    private spinner: NgxSpinnerService,
    private OrderSer: OrderService,
    private matDialog: MatDialog,
    private TabBarServ: TabBarService,
    private toastr: ToastrService,    
    private translate: TranslateService
  ) { 

    translate.addLangs(['en', 'ch']);
    translate.setDefaultLang('en');
    this.set_lang=sessionStorage.getItem('set_lang');
    if(this.set_lang == '' || this.set_lang == undefined || this.set_lang == null)
    {
      translate.setDefaultLang('en');
    }else{
      translate.setDefaultLang(this.set_lang);
    }

    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
    })


    this.columnDefs = [
      {
        width: 28,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        resizable: false,
        filter: false,
        sortable: false
      },
      {
        headerName: "OrderNo",
        field: "OrderNo",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 100,
        resizable: true,
        cellRenderer: function (params) {           
            return `<span style="cursor:pointer" data-action-type="InvoiceDet">${params.data.OrderNo}</span>`;          
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "OrderDate",
        field: "OrderDate",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 150,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
       
      {
        headerName: "TotalPcs",
        field: "TotalPcs",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 100,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.TotalPcs.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "TotalCts",
        field: "TotalCts",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 100,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.TotalCts.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "TotalAmount",
        field: "TotalAmount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 100,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.TotalAmount.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "AvgDiscPer",
        field: "AvgDisc",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 100,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.AvgDisc.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "PricePerPiece",
        field: "PricePerPc",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 100,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.PricePerPc.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      } 
    ];

    this.defaultColDef = {
      resizable: true,
      filter: false,
      sortable: true
    };

    this.rowSelection = "multiple";
  }

  ngOnInit() {
    this.TabBarServ.sharedData$.subscribe(sharedData => {
      if (sharedData == "Confirmation Summary") {
      }
    });
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.LoadGridData()
  }

  
  onSelectionChanged(event: any) {
    this.selectedRow = event.api.getSelectedRows(); 
  }

  onGridRowClicked(eve: any) {
    if (eve.event.target !== undefined) {
      let actionType = eve.event.target.getAttribute("data-action-type");
      if (actionType == "InvoiceDet") {        
        localStorage.removeItem("ID");
        localStorage.setItem("ID", eve.data.InvoiceId);
        this.TabBarServ.setData("Confirmation Detail (" + eve.data.InvoiceId + ")");
      }
    }
  }

  LoadGridData() {
    this.spinner.show()
    this.OrderSer.OrderSummaryFill().subscribe(ResultRes => {
      try {
        if (ResultRes.success == 1) {
          this.gridApi.setRowData(ResultRes.data);
          this.spinner.hide()
        } else {
          this.spinner.hide()
        }
      } catch (error) {
        console.log(error);
        this.spinner.hide()
      }
    });
  }

  public localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.headerName;
    return this.translate.instant(headerIdentifier);
  }


  excelExport(){
    this.spinner.show();

    if(this.selectedRow.length == 0){
      this.spinner.hide()
      this.toastr.warning("Select at least one order")
      return
    }

    var OrderId = this.selectedRow.map(function(val) {
      return val.OrderId;
    }).join(',');

    var mapForm = document.createElement("form");
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = this.apiUrl + 'order/ConfirmOrderExcel';

    var SLmapInput = document.createElement("input");
    SLmapInput.type = "hidden";
    SLmapInput.name = "OrderId";
    SLmapInput.setAttribute("value", OrderId);
    mapForm.appendChild(SLmapInput);

    var UIDmapInput = document.createElement("input");
    UIDmapInput.type = "hidden";
    UIDmapInput.name = "UserID";
    UIDmapInput.setAttribute("value", this.decodedTkn.UserID);
    mapForm.appendChild(UIDmapInput);

    document.body.appendChild(mapForm);
    mapForm.submit();

    this.spinner.hide()
  }
  
}
