import { Component, OnInit, Inject } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import  { environment } from './../../../environments/environment';

@Component({
  selector: 'app-img-video',
  templateUrl: './img-video.component.html',
  styleUrls: ['./img-video.component.css']
})
export class ImgVideoComponent implements OnInit {

  BaseUrl=environment.BaseUrl;

  certiIsImage = false;
  ModalTopic: any;
  FilePath: any;
  html: any;
  StockNo:any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _mdr: MatDialogRef<ImgVideoComponent>,
    private sanitizer: DomSanitizer
  ) {
    
    this.ModalTopic = data.ModalTopic;
    this.FilePath = data.FilePath;
    this.StockNo = data.StockNo;

    if (this.ModalTopic == 'certificat') {
      let certiExt = this.FilePath.split('.').pop();
      if (certiExt.toUpperCase() == 'PDF') {
        this.certiIsImage = false;
      } else {
        this.certiIsImage = true;
      }
    }
  }

  ngOnInit(): void {
    
    if (this.ModalTopic == 'video') {
      this.html = this.sanitizer.bypassSecurityTrustHtml('<iframe   height="auto" src="' + this.FilePath + '" class="det_vdeo"></iframe>');
    } else if (this.ModalTopic == 'certificat') {
      if (this.FilePath.split('.').pop().toUpperCase() == 'PDF') {
        this.html = this.sanitizer.bypassSecurityTrustHtml('<iframe height="auto" src="' + this.FilePath + '" class="det_vdeo"></iframe>');
      } else {
        this.html = this.sanitizer.bypassSecurityTrustHtml('<iframe height="auto" src="' + this.FilePath + '" class="det_vdeo"></iframe>');
      }
    } else if (this.ModalTopic == 'photo') {
      this.html = this.sanitizer.bypassSecurityTrustHtml('<img  height="auto" class="img-responsive stn_img" src="' + this.FilePath + '">');
    }
  }


  CloseDialog() {
    this._mdr.close(false)
  }

  downloadImage(){

    if(this.FilePath.substr(this.FilePath.lastIndexOf('.') + 1).toUpperCase() == 'PDF' ||
    this.FilePath.substr(this.FilePath.lastIndexOf('.') + 1).toUpperCase() == 'PNG' ||
    this.FilePath.substr(this.FilePath.lastIndexOf('.') + 1).toUpperCase() == 'JPG' ||
    this.FilePath.substr(this.FilePath.lastIndexOf('.') + 1).toUpperCase() == 'JPEG' ||
    this.FilePath.substr(this.FilePath.lastIndexOf('.') + 1).toUpperCase() == 'MP4'){
      
      var mapForm = document.createElement("form");
      mapForm.target = "_blank";
      mapForm.method = "POST";
  
      mapForm.action = this.BaseUrl+'Helpers/DownloadMedia';
  
      let obj = {
        url:this.FilePath,
        ext:this.FilePath.substr(this.FilePath.lastIndexOf('.') + 1).toUpperCase(),
        stoneId: this.StockNo
      }
  
      Object.keys(obj).forEach(function(param){
        if(obj[param]){
          var mapInput = document.createElement("input");
          mapInput.type = "hidden";
          mapInput.name = param;
          mapInput.setAttribute("value", obj[param]);
          mapForm.appendChild(mapInput);
        }
      });
      document.body.appendChild(mapForm);
      mapForm.submit();
      document.body.removeChild(mapForm);
    }else{
      var mapForm = document.createElement("form");
      mapForm.target = "_blank";
      mapForm.method = "POST";
  
      mapForm.action = this.BaseUrl+'Helpers/DownloadMedia';
  
      let obj = {
        url:this.FilePath,
        ext:'html',
        stoneId: this.StockNo
      }
  
      Object.keys(obj).forEach(function(param){
        if(obj[param]){
          var mapInput = document.createElement("input");
          mapInput.type = "hidden";
          mapInput.name = param;
          mapInput.setAttribute("value", obj[param]);
          mapForm.appendChild(mapInput);
        }
      });
      document.body.appendChild(mapForm);
      mapForm.submit();
      document.body.removeChild(mapForm);
    }

   
  }
}
