import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PendingOrderService {

  constructor(private http: HttpClient) { }

  private BaseUrl = environment.BaseUrl;

  PendingOrderFill():Observable<any>{
    return this.http.get<any>(this.BaseUrl + 'pendingOder/PendingOrderFill');
  }
}
