import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

import { DDCService } from '../../Service/User/diamond-ddc/diamond-ddc.service';

@Component({
  selector: 'app-add-ddc',
  templateUrl: './add-ddc.component.html',
  styleUrls: ['./add-ddc.component.css']
})
export class AddDDCComponent implements OnInit {

  ModalStateData:any=''

  DDCHour:any=''

  DDCHours_Arr = [
    {id:'2',value:'2'},
    {id:'4',value:'4'},
    {id:'6',value:'6'},
    {id:'8',value:'8'},
    {id:'10',value:'10'},
    {id:'12',value:'12'},
    {id:'14',value:'14'},
    {id:'16',value:'16'},
    {id:'18',value:'18'},
    {id:'20',value:'20'},
    {id:'22',value:'22'},
    {id:'24',value:'24'},
  ]

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _mdr: MatDialogRef<AddDDCComponent>,
    private spinner: NgxSpinnerService,
    private toastr : ToastrService,
    private DDCServ : DDCService
  ) {
    this.ModalStateData = data
   }

  ngOnInit() {
  }

  Close(){
    this._mdr.close()
  }

  AddDDC(){
    this.spinner.show()
    let DDCdata = this.ModalStateData.data.map((item)=>{
      return { DiamondId:item.DiamondId,DDCHour:this.DDCHour }
    })
    let DDCObj = {
      DiamondList:DDCdata
    }

    this.DDCServ.DDCSave(DDCObj).subscribe((SRes=>{
      this.spinner.hide()
      try {
        if(SRes.success == true){
          this.toastr.success('DDC saved successfully')
          this._mdr.close({success:1})
        }else{
          this.toastr.warning('Failed to save DDC')
          this._mdr.close({success:1})
        }
      } catch (error) {
        this.toastr.warning(error)
      }
    }))

  }
}
