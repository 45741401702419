import { Component, OnInit,Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";
import { TabBarService } from  '../../Service/tab-bar.service'
import * as $ from "jquery";
import { JwtHelperService } from '@auth0/angular-jwt';
import {TranslateService} from '@ngx-translate/core';
import { DashboardService } from '../../Service/User/dashboard/dashboard.service';
import { Session } from 'protractor';
import { parse } from 'querystring';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {

  @Output() langEvent = new EventEmitter<string>();
  pageTitle: any = "";
  set_lang:any='';
  SalesPersondata:any=[];
  Certi_list:any='';
  decodeHelper = new JwtHelperService();
  decodedTkn = this.decodeHelper.decodeToken(localStorage.getItem('token'));
  isConfirm:any=false;
  RMBRATE:any=0;
  isGuestLogin: any='false';
  constructor(
    private router: Router,
    private TabBarServ: TabBarService,
    private translate: TranslateService,
    private DashboardServ : DashboardService,
    ) {
    this.pageTitle = "Home";
    translate.addLangs(['en', 'ch']);
    translate.setDefaultLang('en');
    this.set_lang=sessionStorage.getItem('set_lang');
    if(this.set_lang == '' || this.set_lang == undefined || this.set_lang == null)
    {
      this.set_lang='en';
      translate.setDefaultLang('en');
    }else{
      translate.setDefaultLang(this.set_lang);
    }
  }

  ngOnInit(): void {

    sessionStorage.setItem('set_lang',this.decodedTkn.Default_Lang.toString())
    this.set_lang=sessionStorage.getItem('set_lang');
    this.isGuestLogin = localStorage.getItem('isGuestLogin');
    $(".main-container").addClass("sidebar-is-expanded");
    $(".sidebar-container").addClass("sidebar-is-expanded");
    $(".has-submenu").click(function(){
      $(this).toggleClass(
        "is-active"
      );
      $(this).parent().toggleClass(
        "is-active"
      );
    });
    var Dashboard = (function() {
      var global = {
        tooltipOptions: {
          placement: "right"
        },
        menuClass: ".c-menu"
      };


      var sidebarChangeWidth = function sidebarChangeWidth() {
        var $menuItemsTitle = $("li .menu-item__title");

        $(".main-container").toggleClass(
          "sidebar-is-reduced sidebar-is-expanded"
        );
        $(".sidebar-container").toggleClass(
          "sidebar-is-reduced sidebar-is-expanded"
        );
        $(".hamburger-toggle").toggleClass("is-opened");
      };

      return {
        init: function init() {
          $(".js-hamburger").on("click", sidebarChangeWidth);
        }
      };
    })();

    Dashboard.init();

    this.TabBarServ.sharedPageName$.subscribe((sharedPageName) => {
      try {
        this.pageTitle = sharedPageName ? sharedPageName : 'Home';
      } catch (error) {
      }

    })
    this.FillSalesPersonData();
    this.FillRMBRate();
  }

  FillRMBRate(){
    this.DashboardServ.RMBRateFill({}).subscribe((SRes)=>{
      try {
        debugger;
        if(SRes.success == 1 && SRes && SRes.data){
           this.RMBRATE = SRes.data[0].RMB_RATE
        } 
      } catch (error) {
        console.log(error)
      }
    })
  }


  FillSalesPersonData(){
    this.DashboardServ.SalesPersonFill({}).subscribe((SRes)=>{
      try {
        if(SRes.success == 1){
          this.SalesPersondata =  SRes.data[0] 
          //Session["IsConfirm"]= SRes.data[0].IsConfirm;
          sessionStorage.setItem('IsConfirm', SRes.data[0].IsConfirm);
          this.isConfirm =(  sessionStorage.getItem('IsConfirm')) 
        } 
      } catch (error) {
        console.log(error)
      }
    })
  }

  Logout() {
    localStorage.clear();
    this.router.navigateByUrl("/login");
  }

  AddTab(Page: any) {
    this.pageTitle=Page;
    this.TabBarServ.setData(Page);
  }


  langtranslator(lan)
  {
    this.set_lang =lan;
    sessionStorage.setItem('set_lang', lan);
    this.translate.use(lan);
    this.langEvent.next(lan);
  }

  Search_cert_wise(){
    let CertObj= {
      certificate_list:this.Certi_list ? this.Certi_list.split(/[ ,]+/).toString():'',
      DiamondType:0
    }
    localStorage.setItem("SearchPara",JSON.stringify(CertObj).toString())
    let ctn = localStorage.getItem('sctn')
    ctn  =( parseInt(ctn) + 1).toString();
    localStorage.setItem("sctn",ctn)
    this.TabBarServ.setData("Diamond Result (" + ctn + ")");
  }

}
