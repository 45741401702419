import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DiamondBasketService } from "../../Service/User/diamond-basket/diamond-basket.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ImgVideoComponent } from "../img-video/img-video.component";
import { TabBarService } from "../../Service/tab-bar.service";
import {TranslateService} from '@ngx-translate/core';
import { ICellRendererParams } from 'ag-grid-community';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import { OrderService } from "../../Service/User/diamond-order/order.service";
import { AddDDCComponent } from '../add-ddc/add-ddc.component';
import { DashboardService } from '../../Service/User/dashboard/dashboard.service';
@Component({
  selector: 'app-diamond-cart',
  templateUrl: './diamond-cart.component.html',
  styleUrls: ['./diamond-cart.component.css']
})
export class DiamondCartComponent implements OnInit {

  decodeHelper = new JwtHelperService();
  decodedTkn = this.decodeHelper.decodeToken(localStorage.getItem('token'));

  apiUrl = environment.BaseUrl;

  selectedRow = [];
  totalCts: any = 0.0;
  avgRap: any = 0.0;
  avgDisc: any = 0.0;
  ctRate: any = 0.0;
  totAmount: any = 0.0;
  isConfirm:any=false;
  public columnDefs;
  public gridApi;
  public gridColumnApi;
  public defaultColDef;
  public rowSelection;
  public isRowSelectable;
  set_lang:any='';
  TabOpen: boolean = false;
  RMBRATE:any=0;
  constructor(
    private spinner: NgxSpinnerService,
    private DiamondBasketSer: DiamondBasketService,
    private matDialog: MatDialog,
    private TabBarServ: TabBarService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private orderService:OrderService,
    private DashboardServ : DashboardService,
  ) {

    translate.addLangs(['en', 'ch']);
    translate.setDefaultLang('en');
    this.set_lang=sessionStorage.getItem('set_lang');
    if(this.set_lang == '' || this.set_lang == undefined || this.set_lang == null)
    {
      translate.setDefaultLang('en');
    }else{
      translate.setDefaultLang(this.set_lang);
    }

    this.translate.onLangChange.subscribe(() => {
      this.gridApi.refreshHeader();
    })
    this.isConfirm =sessionStorage.getItem('IsConfirm')
    this.columnDefs = [
      {
        width: 28,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        resizable: false,
        filter: false,
        sortable: false
      },
      {
        headerName: "StoneID",
        field: "StockNo",
        width: 114,
        headerClass: "text-left",
        cellStyle: function(params) {
          if (params.data.DiamondStatus != 1) {
              return {color: 'red', backgroundColor: '#FF99CC'};
          }
        },
        cellRenderer: function (params) {
          if (params.data.DiamondStatus == 1) {
            return `<span style="color:#007bff;cursor:pointer" data-action-type="StoneDet">${params.data.StockNo}</span>`;
          } else {
            return `<span style="color:#007bff;cursor:pointer;background-color:#FF99CC;" data-action-type="StoneDet">${params.data.StockNo}</span>`;
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Details",
        headerClass: "text-left",
        width: 85,
        resizable: true,
        filter: false,
        sortable: false,
        cellRenderer: function (params) {
          var htmlStr =  '';
          if (params.data.ImageUrl != "") {
            htmlStr = htmlStr +'<i class="icon-camera" data-action-type="photo" ></i>&nbsp;';
          } else{
            htmlStr = htmlStr +'<i class="icon-camera" data-action-type="photo" style="visibility: hidden;" ></i>&nbsp;';
          }
          if (params.data.VideoUrl != "") {
            htmlStr = htmlStr +'<i class="icon-ico_Movie" data-action-type="video"></i>&nbsp;';
          } else{
            htmlStr = htmlStr +'<i class="icon-ico_Movie" data-action-type="video" style="visibility: hidden;" ></i>&nbsp;';
          }
          if (params.data.CertPDFUrl != "") {
            htmlStr = htmlStr +'<i class="icon-certificate" data-action-type="certificat"></i>';
          } else{
            htmlStr = htmlStr +'<i class="icon-certificate" data-action-type="certificat" style="visibility: hidden;"></i>';
          }
          var html =
            '<span class="det_val">'+htmlStr+'</span>';
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Cert",
        field: "Certi",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Loc",
        field: "Location",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 55,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Shape",
        field: "Shape",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 66,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Carat",
        field: "Carat",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 62,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.Carat.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Color",
        field: "Color",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 63,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Clarity",
        field: "Clarity",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 66,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Cut",
        field: "Cut",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 49,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Pol",
        field: "Polish",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 49,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Sym",
        field: "Symm",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 53,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Flo",
        field: "Flo",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 50,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "ORap",
        field: "RapRate",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 64,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },

      {
        headerName: "CartROff",
        field: "Cart_Discount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 100,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.Discount.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "CartPerCts",
        field: "Cart_PricePerCarat",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 100,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.Discount.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "CartAmount",
        field: "Cart_Amount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 100,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.Discount.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "ROff",
        field: "Discount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 68,
        resizable: true,
        cellRenderer: function (params) {
          // return html;
          if (params.data.Cart_Amount < params.data.Amount) {
            return "<span style='color:red;font-weight:bold' >" + params.data.Discount.toFixed(2) + "</span>";
          } else if (params.data.Cart_Amount > params.data.Amount) {
            return "<span style='color:green;font-weight:bold' >" + params.data.Discount.toFixed(2) + "</span>";
          } else {
            return "<span style='font-weight:bold'>" + params.data.Discount.toFixed(2) + "</span>";
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "PerCrtRate",
        field: "PricePerCarat",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 85,
        resizable: true,
        cellRenderer: function (params) {
          // return html;
          if (params.data.Cart_Amount < params.data.Amount) {
            return "<span style='color:red;font-weight:bold' >" + params.data.PricePerCarat.toFixed(2) + "</span>";
          } else if (params.data.Cart_Amount > params.data.Amount) {
            return "<span style='color:green;font-weight:bold' >" + params.data.PricePerCarat.toFixed(2) + "</span>";
          } else {
            return "<span style='font-weight:bold'>" + params.data.PricePerCarat.toFixed(2) + "</span>";
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Amount",
        field: "Amount",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 85,
        resizable: true,
        cellRenderer: function (params) {
          // return html;
          if (params.data.Cart_Amount < params.data.Amount) {
            return "<span style='color:red;font-weight:bold' >" + params.data.Amount.toFixed(2) + "</span>";
          } else if (params.data.Cart_Amount > params.data.Amount) {
            return "<span style='color:green;font-weight:bold' >" + params.data.Amount.toFixed(2) + "</span>";
          } else {
            return "<span style='font-weight:bold'>" + params.data.Amount.toFixed(2) + "</span>";
          }
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "RMB Amount",
        field: "RMB_AMOUNT",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 85,
        resizable: true,
        cellRenderer: function(params) {
          var html = "<span>" + params.data.RMB_AMOUNT.toFixed(2) + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "ReportNo",
        field: "ReptNo",
        cellStyle: { "text-align": "center" },
        headerClass: "text-left",
        width: 100,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Measurement",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 110,
        resizable: true,
        cellRenderer: function (params) {
          var html = `<span>${params.data.DMin}-${params.data.DMax}*${params.data.DHeight}</span>`;
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Table",
        field: "Tbl",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 58,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.Tbl + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Depth",
        field: "TableDepth",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 66,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.TableDepth + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "LU",
        field: "Luster",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 47,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Brn",
        field: "Brown",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 55,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "TB",
        field: "TblBlack",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 47,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "SB",
        field: "SideBlack",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 48,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "TI",
        field: "TableIncluction",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 64,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "Culet",
        field: "Culet",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 60,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "GirPer",
        field: "GirdlePer",
        headerClass: "text-left",
        cellStyle: { "text-align": "right" },
        width: 59,
        resizable: true,
        cellRenderer: function (params) {
          var html = "<span>" + params.data.GirdlePer + "</span>";
          return html;
        },
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "HA",
        field: "HNA",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 60,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      },
      {
        headerName: "KeyToSymbol",
        field: "Comment",
        headerClass: "text-left",
        cellStyle: { "text-align": "center" },
        width: 150,
        resizable: true,
        headerValueGetter: this.localizeHeader.bind(this)
      }
    ];

    this.defaultColDef = {
      resizable: true,
      filter: false,
      sortable: true
    };

    this.rowSelection = "multiple";
  }

  ngOnInit() {
    this.TabBarServ.sharedData$.subscribe(sharedData => {
      if (sharedData == "Basket") {
      }
    });
    this.FillRMBRate();
  }

  FillRMBRate(){
    this.DashboardServ.RMBRateFill({}).subscribe((SRes)=>{
      try {
        debugger;
        if(SRes.success == 1 && SRes && SRes.data){
           this.RMBRATE = SRes.data[0].RMB_RATE
        } 
      } catch (error) {
        console.log(error)
      }
    })
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.LoadGridData()
  }

  onSelectionChanged(event: any) {
    this.selectedRow = event.api.getSelectedRows();
    this.doCalculation();
  }

  onGridRowClicked(eve: any) {
    if (eve.event.target !== undefined) {
      let actionType = eve.event.target.getAttribute("data-action-type");

      if (actionType == "photo") {
        this.OpenDetailModal("photo", eve.data.ImageUrl,eve.data.StockNo);
      } else if (actionType == "video") {
        this.OpenDetailModal("video", eve.data.VideoUrl,eve.data.StockNo);
      } else if (actionType == "certificat") {
        //this.OpenDetailModal("certificat", eve.data.CertPDFUrl,eve.data.StockNo);
        window.open(eve.data.CertPDFUrl, "_blank");
      } else if (actionType == "StoneDet") {
        localStorage.removeItem("SD");
        localStorage.setItem("SD", eve.data.StockNo);
        localStorage.setItem(eve.data.StockNo, JSON.stringify(eve.data))
        this.TabBarServ.setData("Stone Detail (" + eve.data.StockNo + ")");
      }
    }
  }

  doCalculation() {
    if (this.selectedRow.length == 0) {
      this.totalCts = 0;
      this.avgRap = 0;
      this.avgDisc = 0;
      this.ctRate = 0;
      this.totAmount = 0;
    } else {
      this.totalCts = this.selectedRow.reduce((acc, val) => {
        return acc + parseFloat(val.Carat);
      }, 0);
      this.totalCts = this.totalCts.toFixed(2);

      this.avgRap = this.selectedRow.reduce((acc, val) => {
        return acc + parseFloat(val.Carat) * parseFloat(val.RapRate);
      }, 0);
      this.avgRap /= this.totalCts;
      this.avgRap = this.avgRap.toFixed(2);

      this.totAmount = this.selectedRow.reduce((acc, val) => {
        return acc + parseFloat(val.Amount);
      }, 0);
      this.totAmount = this.totAmount.toFixed(2);

      this.ctRate = this.selectedRow.reduce((acc, val) => {
        return acc + parseFloat(val.Carat) * parseFloat(val.PricePerCarat);
      }, 0);
      this.ctRate /= this.totalCts;
      this.ctRate = this.ctRate.toFixed(2);

      this.avgDisc = 100 - (this.ctRate / this.avgRap) * 100;
      this.avgDisc = this.avgDisc.toFixed(2);
    }
  }

  // detail modal starts
  matDialogRefDteails: MatDialogRef<ImgVideoComponent>;

  OpenDetailModal(ModalTopic, FilePath,StockNo) {
    this.matDialogRefDteails = this.matDialog.open(ImgVideoComponent, {
      data: { ModalTopic, FilePath,StockNo },
      disableClose: false
    });
  }
  // detail modal ends

  clearCalculation() {
    this.gridApi.deselectAll();
    this.totalCts = 0;
    this.avgRap = 0;
    this.avgDisc = 0;
    this.ctRate = 0;
    this.totAmount = 0;
  }

  LoadGridData() {
    this.spinner.show()
    this.DiamondBasketSer.BasketFill().subscribe(ResultRes => {
      try {
        if (ResultRes.success == 1) {
          this.gridApi.setRowData(ResultRes.data);
          this.spinner.hide()
        } else {
          this.spinner.hide()
        }
      } catch (error) {
        console.log(error);
        this.spinner.hide()
      }
    });
  }

  removeFromCart() {
    this.spinner.show();

    if (this.selectedRow.length == 0) {
      this.spinner.hide()
      this.toastr.warning("Select at least one stone")
      return
    }

    var selectedStoneId = this.selectedRow.map(function (val) {
      return val.StockNo;
    }).join(',');

    var payload = {
      "DiamondId": selectedStoneId
    }

    this.DiamondBasketSer.RemoveFromBasket(payload).subscribe(ResultRes => {
      try {
        if (ResultRes.success == 1) {
          this.spinner.hide();
          this.clearCalculation();
          this.toastr.success("Stone(s) removed from cart successfully");
          this.LoadGridData()
          return;
        } else {
          this.clearCalculation();
          this.spinner.hide()
        }
      } catch (error) {
        console.log(error);
        this.spinner.hide();
        this.clearCalculation();
      }
    });
  }

  public localizeHeader(parameters: ICellRendererParams): string {
    let headerIdentifier = parameters.colDef.headerName;
    return this.translate.instant(headerIdentifier);
  }

  excelExport(){
    this.spinner.show();

    if(this.selectedRow.length == 0){
      this.spinner.hide()
      this.toastr.warning("Select at least one stone")
      return
    }

    var selectedStoneId = this.selectedRow.map(function(val) {
      return val.StockNo;
    }).join(',');

    var mapForm = document.createElement("form");
    mapForm.method = "POST"; // or "post" if appropriate
    mapForm.action = this.apiUrl + 'basket/ExcelExport';

    var SLmapInput = document.createElement("input");
    SLmapInput.type = "hidden";
    SLmapInput.name = "DiamondIdList";
    SLmapInput.setAttribute("value", selectedStoneId);
    mapForm.appendChild(SLmapInput);

    var UIDmapInput = document.createElement("input");
    UIDmapInput.type = "hidden";
    UIDmapInput.name = "UserID";
    UIDmapInput.setAttribute("value", this.decodedTkn.UserID);
    mapForm.appendChild(UIDmapInput);

    document.body.appendChild(mapForm);
    mapForm.submit();

    this.spinner.hide()
  }
  GenerateStoneOrder(){
    if(this.selectedRow.length == 0){
      this.toastr.warning("Select at list one stone.")
      return;
    }

    let ChkStoneNotAva = this.selectedRow.filter((option)=> option.DiamondStatus != 1)

    if(ChkStoneNotAva.length != 0){
      this.toastr.warning("Remove business process stone.")
      return;
    }

    let CartList;

    CartList = this.selectedRow.map((item)=>{
      return {
        DiamondId:item.DiamondId,
        StockNo:item.StockNo,
        VStockNo:item.VStockNo,
        ReptNo:item.ReptNo,
        VendorId:item.VendorId,
        StockRefNo:item.StockRefNo,
        LocationId:item.LocationId
      }
    })

  var payLoad = {
    CartList : CartList
    }

    this.orderService.OrderSave(payLoad).subscribe(ResultRes => {
      try {
        if (ResultRes.success == 1) {
          this.spinner.hide();
          this.clearCalculation();
          this.toastr.success("Stone(s) Confirmed successfully");
          return;
        } else {
          this.toastr.error("Somthing went wrong");
          this.clearCalculation();
          this.spinner.hide()
        }
      } catch (error) {
        console.log(error);
        this.spinner.hide();
        this.clearCalculation();
      }
  });


  }

  matDialogRefAddDDC: MatDialogRef<AddDDCComponent>;
  GenerateDDC(){
    if(this.selectedRow.length == 0){
      this.toastr.warning('Please select at least one diamond!')
      return
    }
    let CountAvaStone = this.selectedRow.filter((item) => item.DiamondStatus == 1)
    if(CountAvaStone.length != 0) {
      this.toastr.warning('Please select only Stone Id marked in pink for DDC.')
      return
    }
    this.matDialogRefAddDDC = this.matDialog.open(AddDDCComponent, { data: { data:this.selectedRow}, disableClose: false });
    this.matDialogRefAddDDC.afterClosed().subscribe((Res)=>{
      if(Res){
        if(Res.success == true){
          this.LoadGridData()
        }
      }
    })
  }

}
